import React from 'react';
import FinsWashIcon from '../components/icons/finswash';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { useMedia } from '../utils/hooks';

import items from '../utils/nav-items';
import '../styles/promo-page.css';

const onNavItemSelected = (index: number) => {
  history.pushState({}, 'FinsWash', `/#${items[index].id}`);
  window.location.reload();
};

const PrivacyPage = () => {
  const iconSize = useMedia(
    ['(min-width: 376px)', '(min-width: 275px)', '(min-width: 0px)'],
    [
      { width: '206', height: '40' },
      { width: '249.18', height: '47.54' },
      { width: '166.12', height: '31.69' }
    ],
    { width: '206', height: '40' }
  );

  return (
    <div
      id="TermsNPolicy"
      className="Promo-container"
      style={{ color: 'black', margin: '64px 32px' }}
    >
      <SEO title="Fins Wash Privacy Policy" />

      <div className="Promo-container__header" style={{ marginBottom: 32 }}>
        <FinsWashIcon color="#16478E" {...iconSize} />
      </div>
      <h1>Privacy Policy</h1>
      <p>
        <strong>
          NOTICE: DO NOT USE THIS APPLICATION WHILE DRIVING. THE MAPPING,
          DRIVING DIRECTIONS AND ASSOCIATED LISTING INFORMATION ARE PROVIDED
          SOLELY AS A GUIDELINE. DO NOT RELY ON THIS PRODUCT FOR EMERGENCY
          ASSISTANCE. IF YOU ARE IN A DANGEROUS SITUATION, SEEK PROTECTION FOR
          YOURSELF AND OTHERS, AND CONTACT EMERGENCY SERVICE PERSONNEL USING 911
          SERVICE OR OTHER AVAILABLE SERVICES.
        </strong>
      </p>
      <p>
        <strong>Description of the Product and Service.</strong>
        <br />
        <br />
        As a AAA Member, or FINS customer who may not be a AAA member, you are
        entitled to benefit from the FINS Car Wash services through the AAA Club
        of which you are a member. When traveling outside of your AAA Club’s
        territory, these services may not be available in other clubs. <br />
        <br />
        The “Product” is an Apple iOS compatible software application provided
        by AAA that facilitates your use of AAA FINS Car Wash services. Through
        the Product, you may submit a service request, and we will verify your
        membership, confirm receipt of your request, initiate the FINS Mobile
        user experience, and use the GPS data provided by your iOS device to
        help locate you. We may also provide you with local information that may
        be relevant to your request (e.g., loyalty offers and special
        incentives). The Product may also provide information and links related
        to other AAA products and services, and third party products and
        services. The functions that we provide through the Product may be
        referred to as the “Service.” <br />
        <br />
        These terms of use govern your use of the Product and the Service only;
        other aspects of FINS Car Wash services continue to be governed by the
        applicable terms of your AAA Club. The Product and Service are provided
        by AAA and may not be available to members of all AAA Clubs in the
        United States and is also available to non-AAA members. The Service is
        dependent on the availability of cellular service and Internet
        connectivity that you must obtain.
      </p>
      <p>
        <strong>Product License.</strong>
        <br />
        <br />
        AAA grants you a personal, non-transferable right to use the Product on
        iOS devices that you own or control, subject to the other limitations
        and restrictions in these terms of use. You may not rent, lease, lend,
        sell, redistribute or sublicense the Product. You may not copy,
        decompile, reverse engineer, disassemble, attempt to derive the source
        code of, modify, or create derivative works of the Product, any updates,
        or any part thereof (except as and only to the extent any foregoing
        restriction is prohibited by applicable law). These terms of use will
        govern any upgrades provided by AAA that replace and/or supplement the
        original Product, unless such upgrade is accompanied by a separate
        license in which case the terms of that license will govern.
      </p>
      <p>
        <strong>Privacy and Confidentiality.</strong>
        <br />
        <br />
        Collection: We will collect the following data when you use the Service
        (“Information”):
        <br />
        <br />
        • Any information submitted using the Product, such as your unique
        device identifier (UDID), name, telephone number, membership number,
        vehicle information (including vehicle year, make, model and engine
        size), and the description of your request or event.
        <br />
        • Any technical information provided to us from your device or cellular
        service provider, such as your sending IP address and the date and time
        of the request
        <br />
        • Location information provided via the Global Positions System (GPS)
        functionality of your iOS device
        <br />
        <br />
        Use: We may use the Information to provide the Service to you, to
        communicate with you, and help identify products and services that we
        may offer to you directly or through our service providers. <br />
        <br />
        Sharing; Disclosures: We may share any information that you provide with
        your local AAA Club. We may share the Information with our service
        providers solely for the purposes of providing you the Service.
        <br />
        <br />
        Required Disclosures: We may provide the Information to third parties
        when, in our discretion, circumstances require or justify disclosure.
        For example: (a) a subpoena, warrant, or order issued by a court, person
        or body with jurisdiction compels the production of information; (b) we
        receive a formal request to disclose information in connection with a
        legal action; (c) we believe in good faith that a law, regulation or
        rule requires us to do so; (d) the information is needed or helpful to
        address an emergency that threatens the life, health or security of an
        identifiable person.
        <br />
        <br />
        Storage, Retention and Protection: The Information will be stored on
        servers controlled by AAA or its service providers. It will be retained
        in accordance with our security and records management policies. The
        confidentiality, security, and integrity of the Information will be
        protected through reasonable physical, administrative and technical
        safeguards.
      </p>
      <p>
        <strong>Termination.</strong>
        <br />
        <br />
        The Service, and your license to use the Product, is effective until
        terminated by You or AAA. These Terms and Conditions will continue to
        apply to the Product and Service after termination to the extent that
        you retain the Product or attempt to use the Service. Your rights under
        these Terms of Use will terminate automatically without notice from AAA
        if you fail to comply with any term(s) of these Terms of Use. Upon
        termination of these Terms of Use, You shall cease all use of the
        Product and Service, and destroy all copies, full or partial, of the
        Product.
        <br />
        <br />
        AAA reserves the right to change, suspend, remove, or disable access to
        any Services at any time without notice. In no event will we be liable
        for the removal of the Product.
      </p>
      <p>
        <strong>Cellular Communications and Internet Access.</strong>
        <br />
        <br />
        Use of the Services requires an internet connection and may result in
        charges from your cellular service provider. We are not responsible for
        any such charges. YOU ARE REPONSIBLE TO OBTAIN CELLULAR AND INTERNET
        SERVICE NECESSARY TO USE THE PRODUCT AND SERVICE. AAA DOES NOT WARRANT
        OR GUARANTEE THAT COMMUNICATION FUNCTIONALITY WILL BE AVAILABLE AT ANY
        PARTICULAR TIME OR PLACE. AAA IS NOT RESPONSIBLE FOR ANY ACTS OR
        OMISSIONS OF YOUR CELLULAR COMMUNICATION OR INTERNET SERVICE PROVIDERS,
        OR UNAVAILABILITY OR ERRORS ASSOCIATED WITH THEIR SYSTEMS OR SERVICES.
      </p>
      <p>
        <strong>Third Party Content.</strong>
        <br />
        <br />
        The Service may display, include or make available content, data,
        information, applications or materials from third parties (“Third Party
        Content”), for example, Bing Maps. AAA is not responsible for the
        content, accuracy, completeness, timeliness, validity, copyright
        compliance, legality, decency, quality, route practicability or
        efficiency or any other aspect of such Third Party Content. Any Third
        Party Content contain proprietary content, information and material that
        is protected by applicable intellectual property and other laws,
        including but not limited to copyright and trademark laws. You may not
        will not use such proprietary content, information or materials in any
        way whatsoever except to use of the Product and Services for their
        intended purposes.
      </p>
      <p>
        <strong>Links</strong>
        <br />
        <br />
        The Service may contain links to third party websites or online services
        (“Third Party Websites”). AAA does not have any liability or
        responsibility to you or any other person for Third Party Websites. AAA
        has no responsibility or control over the terms of use or privacy
        practices of Third Party Websites. You are advised to read the terms of
        use and privacy notice of any third party websites to which you connect
        when using the Product.
      </p>
      <p>
        <strong>Physical Safety and Security</strong>
        <br />
        <br />
        Electronic products and communications services can and do experience
        errors, malfunction and failure. IF EVER YOU ARE IN A SITUATION THAT
        PRESENTS A RISK OF PHYSICAL HARM OR DANGER, SEEK SAFETY BEFORE
        ATTEMPTING TO CONTACT AAA. CONTACT LAW ENFORCEMENT OR OTHER EMERGENCY
        SERVICES IF YOU FEEL THREATENED. IF EVER YOU WISH TO CONTACT AAA FROM A
        DANGEROUS SITUATION, DO NOT RELY ON THE PRODUCT OR SERVICE; INSTEAD,
        CALL AAA AT THE NUMBER ON THE BACK OF YOUR MEMBERSHIP CARD (TYPICALLY
        800-222-4357). IF EVER YOU ARE UNCOMFORTABLE RELYING ON THE SERVICE, OR
        SUSPECT THAT THE SERVICE IS NOT FUNCTIONING CORRECTLY, DO NOT RELY ON
        THE SERVICE, AND CALL AAA INSTEAD.
      </p>
      <p>
        <strong>NO WARRANTY</strong>
        <br />
        <br />
        YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE PRODUCT AND SERVICE
        IS PROVIDED WITHOUT WARRANTY OF ANY KIND, AND THAT YOU ARE SOLELY
        RESPONSIBLE FOR THE RISK THAT THE PRODUCT MAY NOT FUNCTION WITH
        SATISFACTORY QUALITY, PERFORMANCE, OR ACCURACY. THE PRODUCT AND SERVICES
        ARE PROVIDED “AS IS” AND “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT
        WARRANTY OF ANY KIND, AND AAA, AND ITS LICENSEOR AND SUPPLERS, HEREBY
        DISCLAIM ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE PRODUCT AND
        ANY SERVICES, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT
        LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY,
        OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF
        ACCURACY, TITLE, AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS. AAA DOES
        NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE PRODUCT, OR PROVIDED BY
        THE SERVICE, WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE
        PRODUCT OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS
        IN THE PRODUCT OR SERVICES WILL BE CORRECTED. NO ORAL OR WRITTEN
        INFORMATION OR ADVICE GIVEN BY AAA OR ITS AUTHORIZED REPRESENTATIVE
        SHALL CREATE A WARRANTY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
        OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A
        CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT FULLY APPLY TO
        YOU.
      </p>
      <p>
        <strong>Limitation of Liability.</strong>
        <br />
        <br />
        TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL AAA OR ITS
        LICENSORS OR SUPPLIERS BE LIABLE FOR PERSONAL INJURY, OR ANY INCIDENTAL,
        SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING,
        WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS
        INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF
        OR RELATED TO YOUR USE OR INABILITY TO USE THE PRODUCT OR SERVICES,
        HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT OR
        OTHERWISE) AND EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR
        PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS
        LIMITATION MAY NOT FULLY APPLY TO YOU. In no event shall our total
        liability to you for all damages (other than as may be required by
        applicable law in cases involving personal injury) exceed the amount of
        fifty dollars ($50.00). The foregoing limitations will apply even if the
        above stated remedy fails of its essential purpose.
      </p>
      <p>
        <strong>Export</strong>
        <br />
        <br />
        You may not use or otherwise export or re-export the Product except as
        authorized by United States law and the laws of the jurisdiction in
        which the Product was obtained. In particular, but without limitation,
        the Product may not be exported or re-exported (a) into any U.S.
        embargoed countries or (b) to anyone on the U.S. Treasury Department’s
        list of Specially Designated Nationals or the U.S. Department of
        Commerce Denied Person’s List or Entity List. <br />
        <br />
        Contact Information: Contact us at{' '}
        <a href="mailto:aaaappsfeedback@national.aaa.com">
          aaaappsfeedback@national.aaa.com
        </a>{' '}
        if you have any concern or questions about these Terms of Use.
        <br />
        <br /> We may amend our Privacy Statement at any time. You should review
        the Legal Notices and Privacy Policy button on this App periodically for
        such changes as we do not separately notify you of any changes.
        <br />
        <br />
        Effective date March 25, 2019.
      </p>
    </div>
  );
};

export default PrivacyPage;
